import {SERVER} from '@/utils/as'
// 修改头像
export const $_web = function (data) {
  return SERVER({
    method: 'post',
    url: '/login',
    data,
  })
}
// 站点列表get
// export const $_website = function (params) {
//   return SERVER({
//     method: 'get',
//     url: '/website',
//     params,
//   })
// }

// 站点列表get
export const $_website = function (params) {
  return SERVER({
    method: 'get',
    url: '/website',
    params,
  })
}
// 修改站点get
export const $_editWebsite = function (data) {
  return SERVER({
    method: 'put',
    url: '/editWebsite',
    data,
  })
}
// 处理状态websiteStatusEdit

export const $_websiteStatusEdit = function (data) {
  return SERVER({
    method: 'post',
    url: '/websiteStatusEdit',
    data,
  })
}
// 添加站点
export const $_addWebsite = function (data) {
  return SERVER({
    method: 'post',
    url: '/addWebsite',
    data,
  })
}
// 批量站点
export const $_websiteAddBatch = function (data) {
  return SERVER({
    method: 'post',
    url: '/websiteAddBatch',
    data,
  })
}
// 删除站点
export const $_deleteWebsite = function (data) {
  return SERVER.delete('/deleteWebsite', {data})
}
// 复制
export const $_copyWebsite = function (data) {
  return SERVER({
    method: 'post',
    url: '/copyWebsite',
    data,
  })
}
export const $_upload = function upload(data) {
  return SERVER({
    url: '/upload',
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    data,
  })
}
