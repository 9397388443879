import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局过滤
import '@/filter/time'
// 细节权限
import '@/directive/index'
// 引入CSS公共文件
import '@/assets/css/normalize.css'
import '@/assets/css/reset.css'
Vue.config.productionTip = false
// 验证码防抖
import SlideVerify from 'vue-monoplasty-slide-verify'
Vue.use(SlideVerify)
// 引入UI
import ElementUI from 'element-ui'
// 主题色自定义
import '@/assets/css/element-theme/index.css'
Vue.use(ElementUI)
// logo
import '@/assets/iconfont/iconfont.css'

// 引入富文本组件

import VueUeditorWrap from 'vue-ueditor-wrap'
Vue.use(VueUeditorWrap)

// 引入数字组件

// 任意传头像
// Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
