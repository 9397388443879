import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui'
import getPageTitle from '@/utils/get-page-title'
import pagesite from '@/views/index/site.vue'
// 解决同一页面二次跳转问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  // 默认登录
  {
    path: '/',
    redirect: '/login',
  },
  // 登录
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
  },
  // 后台首页默认显示
  {
    path: '/site',
    component: () => import('@/views/index/Layout.vue'),
    show: true,
    icon: 'el-icon-s-home',
    role: ['super', 'normal'],
    meta: {
      title: '站点列表',
    },
    children: [
      {
        name: 'pagesite',
        path: '',
        component: pagesite,
      },
    ],
  },
  // 用户管理
  {
    path: '/users',
    component: () => import('@/views/index/Layout.vue'),
    show: true,
    icon: 'el-icon-user-solid',
    role: ['super', 'normal'],
    meta: {
      title: '用户管理',
    },
    children: [
      {
        path: '',
        component: () => import('@/views/users/UsersList.vue'),
        meta: {
          title: '用户列表',
        },
      },
    ],
  },
  // 角色管理
  {
    path: '/role',
    component: () => import('@/views/index/Layout.vue'),
    show: true,
    icon: 'el-icon-view',
    role: ['super', 'normal'],
    meta: {
      title: '角色管理',
    },
    children: [
      {
        path: '',
        component: () => import('@/views/role/RoleList.vue'),
        meta: {
          title: '角色列表',
        },
      },
    ],
  },

  // 站点编辑
  {
    path: '/site',
    component: () => import('@/views/index/Layout.vue'),
    meta: {
      title: '站点列表',
    },
    role: ['super', 'normal'],

    children: [
      {
        name: 'editsite',

        meta: {
          isBack: false,
          title: '站点更改',
        },
        path: '/editsite',
        component: () => import('@/views/EditSite/editsite.vue'),
      },
    ],
  },
  //操作日志
  {
    path: '/systemlogs',
    component: () => import('@/views/index/Layout.vue'),
    meta: {
      title: '操作日志',
    },
    show: true,
    icon: 'el-icon-notebook-2',
    role: ['super', 'normal'],
    children: [
      {
        meta: {
          title: '操作日志',
        },
        path: '',
        component: () => import('@/views/systemlogs/SystemLogs.vue'),
      },
    ],
  },

  // 测试二级路由
  // {
  //   path: '/test2',
  //   component: () => import('@/views/index/Layout.vue'),
  //   meta: {
  //     title: '一级菜单',
  //   },
  //   show: true,
  //   icon: 'el-icon-notebook-2',
  //   role: ['super', 'normal'],
  //   children: [
  //     {
  //       meta: {
  //         title: '菜单1',
  //       },
  //       path: '/test2/2',
  //       component: () => import('@/views/role/RoleList.vue'),
  //     },
  //     {
  //       meta: {
  //         title: '菜单2',
  //       },
  //       path: '/test2/3',
  //       component: () => import('@/views/ee.vue'), //很关键的一步
  //       children: [
  //         {
  //           meta: {
  //             title: '菜单2-1',
  //           },
  //           path: '/test2/3/1',
  //           component: () => import('@/views/role/RoleList.vue'),
  //         },
  //         {
  //           meta: {
  //             title: '菜单2-2',
  //           },
  //           path: '/test2/3/2',
  //           component: () => import('@/views/users/UsersList.vue'),
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: '*',
    redirect: '/',
  },
]
export function Routers() {
  return [...routes]
}
const router = new VueRouter({
  routes: Routers(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      const scrollPosition = sessionStorage.getItem('scrollPosition')
      if (scrollPosition) {
        const position = JSON.parse(scrollPosition)
        return position
      }
      return {x: 0, y: 0}
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    if (localStorage.user_token) {
      // 设置浏览器title
      document.title = getPageTitle(to.meta.title)
      next()
    } else {
      Message.error('注意你的操作')
      next({path: '/login'})
    }
  }
})

export default router
