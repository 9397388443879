// 混入 节流
export const conserve = {
  data() {
    return {
      dis: false,
    }
  },
  methods: {
    throttle() {
      this.dis = true
      setTimeout(() => {
        this.dis = false
      }, 2000)
    },
  },
}
