// 时间过滤器
import {zero} from '@/utils/utils'
import Vue from 'vue'
Vue.filter('timefil', time => {
  let timeobj = new Date(time)
  return (
    timeobj.getFullYear() +
    '/' +
    zero(timeobj.getMonth() + 1) +
    '/' +
    zero(timeobj.getDate()) +
    ' ' +
    zero(timeobj.getHours()) +
    ':' +
    zero(timeobj.getMinutes())
  )
  // new Date(a).getFullYear() + '/' + (new Date(a).getMonth() + 1) + '/' + new Date(a).getDate()
})
