import Vue from 'vue'
// 自定义指令
//按钮权限
Vue.directive('role', {
  inserted(dom, val) {
    // console.log(dom, val)
    if (!val.value.includes(localStorage.role)) dom.remove()
  },
})
// <!-- 自定义细节权限 -->
//   <span v-role="['super']">账号列表</span>
