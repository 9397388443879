<template>
  <el-card class="box-card" ref="site">
    <div slot="header" class="clearfix">
      <span class="siteTitle">站点列表</span>
      <el-radio-group v-model="radio" @input="ChangeLabel">
        <el-radio :label="2">全部</el-radio>
        <el-radio :label="1">已处理</el-radio>
        <el-radio :label="0">未处理</el-radio>
      </el-radio-group>
      <div>
        <el-button
          style="float: right; padding: 3px 0; margin-left: 10px"
          type="text"
          @click="BatchAddSite"
        >
          批量添加
        </el-button>
        <el-button style="float: right; padding: 3px 0" type="text" @click="addsite">
          添加网站
        </el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-table
      @sort-change="changeTableSort"
      :data="SiteTableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <!-- id -->
      <el-table-column :sortable="'custom'" label="id" min-width="4%">
        <template slot-scope="scope">
          <span>{{ scope.row.site_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="域 名" min-width="20%">
        <template slot-scope="scope">
          <!-- gannm.com -->

          <a
            :href="'https://' + scope.row.domain"
            target="_blank"
            style="text-decoration: none; color: #000; cursor: pointer"
          >
            {{ scope.row.domain }}
          </a>

          <!-- <span>{{ scope.row.domain }}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="域名过期时间" min-width="8%">
        <template slot-scope="scope">
          <span>{{ scope.row.expiration_time }}</span>
        </template>
      </el-table-column>
      <!-- 是否处理 -->
      <el-table-column label="处理状态" min-width="5%">
        <template slot-scope="scope">
          <el-switch
            @change="ChangeHandle(scope.row)"
            :active-value="activeV"
            :inactive-value="inactiveV"
            active-color="#13ce66"
            v-model="scope.row.status"
            inactive-color="#ff4949"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="13%">
        <template slot-scope="scope">
          <span>{{ scope.row.created_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" min-width="5%">
        <template slot-scope="scope">
          <span>{{ scope.row.operator }}</span>
        </template>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column label="备 注" min-width="15%">
        <template slot-scope="scope">
          <el-input
            style="width: 100%"
            type="textarea"
            autosize
            placeholder="请输入内容"
            :disabled="true"
            v-model="scope.row.remark"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="收 录" min-width="7%">
        <template slot-scope="scope">
          <span>{{ scope.row.number_record }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操 作" min-width="23%">
        <template slot-scope="scope">
          <el-button size="mini" @click="ObtainSite(scope.row)">收录</el-button>
          <el-button size="mini" @click="FREditSite(scope.row)">编辑</el-button>
          <el-button size="mini" @click="EditSite(scope.row.site_id)">配置</el-button>
          <el-button size="mini" @click="CopySite(scope.row.site_id)">复制</el-button>
          <el-button size="mini" type="danger" @click="DeleteSite(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div style="display: flex; justify-content: center">
      <el-pagination
        background
        @current-change="CurrentChange"
        :current-page="current_page"
        :page-size="per_page"
        layout="total, prev, pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑 -->
    <el-dialog title="编辑站点" center :visible.sync="FormSiteEdit">
      <el-form :rules="Editrules" ref="Editform" :model="formEdit">
        <el-form-item label="域名" :label-width="dialoglWidth" prop="domain">
          <el-input class="TitleInput" v-model="formEdit.domain" placeholder="输入域名"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="dialoglWidth">
          <el-input
            style="width: 100%"
            type="textarea"
            autosize
            placeholder="请输入内容"
            v-model="formEdit.remark"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="过期时间"
          class="TimeExpiration"
          prop="expiration_time"
          :label-width="dialoglWidth"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formEdit.expiration_time"
            type="date"
            placeholder="输入域名过期时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="收录数量" :label-width="dialoglWidth" prop="number_record">
          <el-input-number v-model="formEdit.number_record" :step="1" :min="0" step-strictly />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="dis" @click="EditTrueSite(formEdit)">确 定</el-button>
        <el-button @click="CencelEdit">取消</el-button>
      </div>
    </el-dialog>
    <!-- 增加 -->
    <el-dialog title="添加网站" center :visible.sync="FormSite">
      <el-form :rules="rules" ref="form" :model="form">
        <el-form-item label="域名" :label-width="dialoglWidth" prop="domain">
          <el-input class="TitleInput" v-model="form.domain" placeholder="输入域名"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="dialoglWidth">
          <el-input class="TitleInput" v-model="form.keyword" placeholder="输入备注"></el-input>
        </el-form-item>
        <el-form-item
          label="过期时间"
          class="TimeExpiration"
          prop="expiration_time"
          :label-width="dialoglWidth"
        >
          <el-date-picker
            style="width: 100%"
            v-model="form.expiration_time"
            type="date"
            placeholder="输入域名过期时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="dis" @click="TrueEditSite('form')">确 定</el-button>
        <el-button @click="FormSite = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量 -->
    <el-dialog title="批量添加网站" center :visible.sync="BatchAddDialogl">
      <el-form :rules="Batch" ref="Batch" :model="BatchForm">
        <el-form-item
          label="过期时间"
          class="TimeExpiration"
          prop="time"
          :label-width="dialoglWidth"
        >
          <el-date-picker
            style="width: 100%"
            v-model="BatchForm.time"
            type="date"
            placeholder="输入域名过期时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="批量域名" prop="name" :label-width="dialoglWidth">
          <el-input
            style="width: 100%"
            type="textarea"
            :autosize="{minRows: 3}"
            placeholder="请输入域名，不同域名用 “,” 隔开,如' baidu.com , renmin.com , likou.com '"
            v-model="BatchForm.name"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="dis" @click="TrueBatch()">确 定</el-button>
        <el-button @click="BatchAddDialogl = false">取 消</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  $_website,
  $_editWebsite,
  $_addWebsite,
  $_deleteWebsite,
  $_upload,
  $_copyWebsite,
  $_websiteAddBatch,
  $_websiteStatusEdit,
} from '@/apis/site'
import {conserve} from '@/mixins/index'
import {Loading} from 'element-ui'
import axios from 'axios'
export default {
  name: 'pagesite',
  mixins: [conserve],
  beforeRouteEnter(to, from, next) {
    // console.log(from)
    if (from.name == 'editsite') {
      to.meta.isBack = true
      // console.log(from)
      //判断是从哪个路由过来的，
    }
    next()
  },
  async activated() {
    if (!this.$route.meta.isBack) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 获取列表
      let loading = Loading.service({
        lock: true,
        text: '数据加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
      })

      let res = await $_website({
        page: 1,
        statusType: 2,
      })
      // console.log('res:', res)

      if (
        res.data.data.data === null ||
        res.data.data.data.length === 0 ||
        res.data.data.data === undefined
      ) {
        console.log(res)
      } else {
        // console.log('获取列表', res)
        this.SiteTableData = res.data.data.data
        this.total = res.data.data.total
        this.current_page = res.data.data.current_page //当前页
        this.per_page = res.data.data.per_page //当前页条数
      }
      // 以服务的方式调用的 Loading 需要异步关闭
      loading.close()
    } else {
      document.querySelector('main').scrollTo({
        top: sessionStorage.getItem('site_scroll'),
      })
    }
    // console.log(sessionStorage.getItem('site_scroll'))
    // console.log(document.querySelector('main'))

    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
  },

  data() {
    return {
      // 滚动距离
      handleScroll: '',
      // 排序
      sort: '',
      // 是否处理
      radio: 2,
      activeV: 1,
      inactiveV: 0,
      // 批量添加
      BatchForm: {
        time: '',
        name: '',
      },

      BatchAddDialogl: false,
      // 编辑
      FormSiteEdit: false,
      formEdit: {},
      //弹框
      FormSite: false,
      form: {
        damain: '',
        remark: '',
        expiration_time: '',
      },
      dialoglWidth: '140px',
      SiteTableData: [],
      per_page: 100, //每页显示多少条
      // multipleSelection: [], //多选数据
      current_page: 1, //当前页
      total: 1, //总数
      rules: {
        domain: [{required: true, message: '输入域名', trigger: 'blur'}],
        expiration_time: [{required: true, message: '请输入域名过期时间', trigger: 'blur'}],
      },
      Batch: {
        name: [{required: true, message: '输入域名', trigger: 'blur'}],
        time: [{required: true, message: '请输入域名过期时间', trigger: 'blur'}],
      },
      Editrules: {
        domain: [{required: true, message: '输入域名', trigger: 'blur'}],
        expiration_time: [{required: true, message: '请输入域名过期时间', trigger: 'blur'}],
        number_record: [{required: true, message: '请输入收录数量', trigger: 'blur'}],
      },
    }
  },
  async created() {
    // 获取列表
    let loading = Loading.service({
      lock: true,
      text: '数据加载中......',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.1)',
    })

    let res = await $_website({
      page: 1,
      statusType: 2,
    })
    // console.log('res:', res)

    if (
      res.data.data.data === null ||
      res.data.data.data.length === 0 ||
      res.data.data.data === undefined
    ) {
      console.log(res)
    } else {
      // console.log('获取列表', res)
      this.SiteTableData = res.data.data.data
      this.total = res.data.data.total
      this.current_page = res.data.data.current_page //当前页
      this.per_page = res.data.data.per_page //当前页条数
    }

    // console.log(sessionStorage.getItem('site_scroll'))

    // document.querySelector('main').scrollTo({
    //   top: sessionStorage.getItem('site_scroll'),
    // })
    // console.log('SiteTableData', this.SiteTableData)

    // 以服务的方式调用的 Loading 需要异步关闭
    loading.close()
  },

  methods: {
    // 获取网站收录
    async ObtainSite(row) {
      let loading = Loading.service({
        lock: true,
        text: '数据加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
      })
      try {
        const response = await axios.get('https://zxmysql.nativemobi.com/googleIncluded', {
          params: {
            site: row.domain,
          },
        })
        let res = await $_editWebsite({
          site_id: row.site_id,
          domain: row.domain,
          remark: row.remark,
          expiration_time: row.expiration_time,
          number_record: response.data.result,
        })
        if (res.status == 200) {
          this.$message({
            message: '获取成功',
            type: 'success',
            showClose: true,
          })
        }
        this.GetSiteList(this.current_page)
        // console.log('response:', response.data.result)
      } catch (error) {
        console.error(error)
      }
      loading.close()
    },
    async changeTableSort(a) {
      let loading = Loading.service({
        lock: true,
        text: '数据加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
      })
      //  ascending == 升序   descending
      if (a.order == 'ascending') {
        this.order = 1
      } else if (a.order == 'descending') {
        this.order = 2
      } else {
        // console.log(111)
        this.order = ''
      }
      // console.log('a:', a.order)
      let res = await $_website({
        page: this.current_page,
        statusType: this.radio,
        sort_type: this.order,
      })
      // console.log('213123', res)
      this.SiteTableData = res.data.data.data
      this.total = res.data.data.total
      this.current_page = res.data.data.current_page //当前页
      this.per_page = res.data.data.per_page //当前页条数
      this.sort = res.data.data.sort
      loading.close()
    },
    // 处理？
    async ChangeLabel(value) {
      // 未处理 0
      // 已处理 1
      // 全  部 2
      let loading = Loading.service({
        lock: true,
        text: '数据加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
      })
      let res = await $_website({
        page: 1,
        statusType: value,
        sort_type: this.order,
      })
      // console.log('处理res:', res)
      this.SiteTableData = res.data.data.data
      this.total = res.data.data.total
      this.current_page = res.data.data.current_page //当前页
      this.per_page = res.data.data.per_page //当前页条数
      loading.close()

      // console.log('res:', res)
    },
    // 处理激活态
    async ChangeHandle(va) {
      // console.log('处理', va.status)
      let flag = ''
      if (va.status == 1) {
        flag = 0
      } else if (va.status == 0) {
        flag = 1
      }
      // console.log('传送的status:', flag)
      let res = await $_websiteStatusEdit({
        site_id: va.site_id,
        status: flag,
      })
      // console.log('status:', va.status)
      // console.log('site_id:', va.site_id)
      // console.log('res:', res)

      if (res.data.status == 200) {
        this.$message({
          message: '更改成功',
          type: 'success',
          showClose: true,
        })
      }
    },
    TrueBatch() {
      // let arr = this.BatchForm.name.split(/[，,]/).map(item => item.trim())
      // console.log('arr:', arr)
      this.throttle()
      this.$refs['Batch'].validate(async valid => {
        // console.log(valid)
        if (valid) {
          let res = await $_websiteAddBatch({
            url_string: this.BatchForm.name,
            expiration_time: this.BatchForm.time,
          })
          // console.log(res)
          if (res.data.status == 200) {
            this.$message({
              message: '添加成功',
              type: 'success',
              showClose: true,
            })
          }
          // console.log(res)
          this.GetSiteList()

          this.BatchAddDialogl = false
          // console.log('res:', res)
          // console.log(this.BatchForm)
        } else {
          console.log('注意规则')
          return false
        }
      })
      // console.log(this.BatchForm)
    },
    // 批量添加
    BatchAddSite() {
      ;(this.BatchForm = {
        time: '',
        name: '',
      }),
        (this.BatchAddDialogl = true)
    },
    FREditSite(data) {
      this.FormSiteEdit = true
      this.formEdit = data
    },
    // 获取列表
    async GetSiteList(p) {
      let res = await $_website({
        page: p,
        statusType: this.radio,
      })
      // console.log('213123', res)
      this.SiteTableData = res.data.data.data
      this.total = res.data.data.total
      this.current_page = res.data.data.current_page //当前页
      this.per_page = res.data.data.per_page //当前页条数
      // console.log('SiteTableData', this.SiteTableData)
    },
    // 复制网址
    async CopySite(id) {
      // console.log(id)
      let res = await $_copyWebsite({site_id: id})
      if (res.data.status == 200) {
        this.$message({
          message: '复制成功',
          type: 'success',
          showClose: true,
        })
      }
      this.GetSiteList()

      // console.log('复制', res)
    },
    // 删除网站
    DeleteSite(data) {
      this.$confirm('此操作将永久删除该网站, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          // console.log(data)
          let res = await $_deleteWebsite({
            site_id: data.site_id,
            domain: data.domain,
          })
          if (res.status == 200) {
            this.$message({
              message: '删除成功',
              type: 'success',
              showClose: true,
            })
            this.GetSiteList()
          }
          // console.log(res)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    addsite() {
      this.form = {
        damain: '',
        remark: '',
        expiration_time: '',
      }
      this.FormSite = true
    },
    // 添加网站
    TrueEditSite(form) {
      // console.log('编辑添加网站:', this.form)

      this.throttle()
      this.$refs[form].validate(async valid => {
        // console.log(valid)
        if (valid) {
          let res = await $_addWebsite(this.form)
          // console.log('11', res)
          if (res.data.status == 200) {
            this.$message({
              message: '添加成功',
              type: 'success',
              showClose: true,
            })
          }
          // console.log(res)
          this.GetSiteList()

          this.FormSite = false
        } else {
          console.log('注意规则')
          return false
        }
      })
      // console.log(this.form)
    },
    // 取消
    CencelEdit() {
      this.FormSiteEdit = false
    },
    // 完成
    async EditTrueSite(data) {
      // console.log('编辑网站:', data)

      this.throttle()
      this.$refs['Editform'].validate(async valid => {
        // console.log(valid)
        if (valid) {
          let res = await $_editWebsite({
            site_id: data.site_id,
            domain: data.domain,
            remark: data.remark,
            expiration_time: data.expiration_time,
            number_record: data.number_record,
          })
          if (res.status == 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
              showClose: true,
            })
          }
          this.GetSiteList(this.current_page)

          this.FormSiteEdit = false
        } else {
          console.log('注意规则')
          return false
        }
      })
    },
    // 站点跳转编辑
    EditSite(id) {
      // console.log(this.handleScroll)
      sessionStorage.setItem('site_scroll', this.handleScroll)
      sessionStorage.setItem('site_id', id)
      this.$router.push(`/editsite?id=${id}`)
    },
    // 分页
    async CurrentChange(val) {
      // console.log('page', val)
      // console.log('radio', this.radio)
      let res = await $_website({
        page: val,
        statusType: this.radio,
        sort_type: this.order,
      })
      // console.log(res)
      this.SiteTableData = res.data.data.data
      this.total = res.data.data.total
      this.current_page = res.data.data.current_page //当前页
      this.per_page = res.data.data.per_page //当前页条数
      // console.log('分页', res)
      // console.log(`当前页: ${val}`)
    },
  },
  mounted() {
    // document.querySelector('main').scrollTo({
    //   top: sessionStorage.getItem('site_scroll'),
    // })
    // 监听滚动事件
    // window.addEventListener('scroll', e => {
    //   console.log(e.target.scrollTop)
    // })
    document.querySelector('main').addEventListener('scroll', e => {
      this.handleScroll = e.target.scrollTop
      // console.log(e.target.scrollTop)
    })
  },
}
</script>

<style lang="scss" scoped>
:deep(.cell) {
  text-align: center;
}
// .TimeExpiration {
// }
.siteTitle {
  font-size: 25px;
  font-weight: 600;
}
.ColorChange {
  display: flex;
  .colorInput {
    width: 150px;
    margin-right: 20px;
  }
}
// 标题哦
// .TitleInput {
//   // width: 300px;
// }
.clearfix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-button {
    width: 90px;
    background-color: #304156;
    color: #fff;
    height: 30px;
  }
}
.demo-table-expand {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
}
.present {
  width: 300px;
}
:deep(.is-leaf) {
  padding-left: 0 !important;
}
.goodsimg {
  width: 50px;
  height: 50px;
}
.el-pagination {
  margin-top: 20px;
}
:deep(.is-leaf) {
  padding-left: 5px;
}
// 商品图片
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
