<template>
  <div id="app">
    <keep-alive include="PageSite">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '后台管理系统',
    }
  },
  // created() {
  //   document.title = '后台管理系统'
  // },
  // beforeCreate() {
  //   document.title = '后台管理系统'
  // },
  mounted() {
    document.title = '后台管理系统'
  },
}
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
