import Vue from 'vue'
//实现vuex
import Vuex from 'vuex'
Vue.use(Vuex)
//创建仓库
export default new Vuex.Store({
  state: {
    //调用state方法 this.$store.state.方法名
    num: 1,
    //存放数据
  },
  getters: {
    //调用getters方法 this.$store.getters.方法名
    NumChange(state) {
      return state.num.toFixed(2)
    },
    addnum(state) {
      console.log(state.num)
      return state.num
    },
    //计算属











  },
  mutations: {
    //调用mutation方法 this.$store.commit('方法名', 参数)
    //同步修改state
    add(state, num1) {
      console.log('state:', state)
      console.log('num1:', num1)
      state.num += num1
      
    },
  },
  actions: {
    test(a) {
      console.log('a action:', a)
    },
    //触发一个异步 this.$store.dispatch('方法名')
    //异步修改state
  },

  modules: {
    //模块
  },
})
