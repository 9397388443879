import router from '@/router'
import as from 'axios'
import {Message} from 'element-ui'

export const baseurl = 'https://buildsiteapi.nativemobi.com'   

export const SERVER = as.create({
  baseURL: baseurl,
  // 超时时间设置
  timeout: 20000,
})
SERVER.interceptors.request.use(
  function (config) {
    // 在发送请求之前添加token
    // config.headers.Authorization = localStorage.token
    // config.headers.Token = 'meXYQShk7B75BB17ED364495B670C53FD825DA79'
    // config.headers['Access-Token'] = 'meXYQShk7B75BB17ED364495B670C53FD825DA79'

    const token = localStorage.getItem('user_token')
    // console.log(token)
    if (token) {
      config.headers['Access-Token'] = token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 响应拦截器
// // 添加响应拦截器
SERVER.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.status == 4001) {
        // console.log('11', response)
        // Message.error('用户状态有误!')
        router.push('/login')
      } else if (response.data.status == 5003) {
        //提示
        Message.error(response.data.data)
      } else if (response.data.status == 5004) {
        Message.error(response.data.data)
      } else if (response.data.status == 4003) {
        //没有权限,提示
        Message.error(response.data.data)
      } else {
        return response
      }
    }

    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

